import React, { useRef } from "react";
import Layout from "@hoc/layout/Layout";
import ServiceCard from "@components/OurServicesPage/ServiceCard";
import Header from "@components/OurServicesPage/Header";
import {useTranslation} from "react-i18next";
import qrPayments from "@assets/svg/ourServices/qr_payments.svg";
import linkIcon from "@assets/svg/ourServices/link_icon.svg";
import paymentGateway from "@assets/svg/ourServices/payment_gateway.svg";
import plugin from "@assets/svg/ourServices/plugin.svg";
import useMouseLight from "@utils/hooks/useMouseLight";

const OurServices = () => {
  const { t } = useTranslation();
  const ourServicePageRef = useRef(null);
  useMouseLight(ourServicePageRef);

  return (
    <Layout>
      <div className="flex flex-col items-center bg-brand-dark-blue">
        <div className="fixed" ref={ourServicePageRef}/>
        <Header/>
        <div className="grid px-5 w-full md:w-5/6 lg:w-2/3 md:grid-cols-2 gap-5 mb-28">
          <ServiceCard
            id="1"
            title={t('qrPayments')}
            description={t('qrPaymentsDescription')}
            expandedDescription={<>
              <div className="mb-2">
                {t('qrPaymentsFirstParagraph')}
              </div>
              <div>
                {t('qrPaymentsSecondParagraph')}
              </div>
            </>}
            image={qrPayments}
          />
          <ServiceCard
            id="2"
            title={t('payByLinkTitle')}
            description={t('payByLinkDescription')}
            expandedDescription={
              <div>
                <div className="mb-2">{t('payByLinkParagraph')}</div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/watch?v=fXpXiQASpyg"
                  className="underline italic font-medium" onClick={(e) => e.stopPropagation()}>
                  {t('payByLinkVideoTutorial')}
                </a>
              </div>
            }
            image={linkIcon}
          />
          <ServiceCard
            id="3"
            title={t('paymentGatewayTitle')}
            description={t('paymentGatewayDescription')}
            image={paymentGateway}
            expandedDescription={
              <div>
                <div className="mb-2">{t('paymentGatewayParagraph')}</div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://payments.doc.pokpay.io/"
                  className="underline italic font-medium" onClick={(e) => e.stopPropagation()}>
                  {t('paymentGatewayDoc')}
                </a>
              </div>
            }
          />
          <ServiceCard
            id="4"
            title={t('availableECommercePlugins')}
            description={t('availableECommercePluginsDescription')}
            expandedDescription={
              <div>
                <div className="mb-3">
                  <span className="font-semibold">PHP SDK</span>
                  <div>
                    {t('phpSdkParagraph')}
                  </div>
                </div>
                <div className="mb-5">
                  <span className="mt-3 text-sm">Prestashop Payments Plugin</span>
                  <div className="italic flex flex-wrap">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.pokpay.io/downloads/plugins/prestashop/1.6/pokpaymentgateway-prestashop-1.6.zip"
                      className="underline font-medium mr-3" onClick={(e) => e.stopPropagation()}>Prestashop Plugin 1.6</a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.pokpay.io/downloads/plugins/prestashop/1.7/pokpaymentgateway-prestashop-1.7.zip"
                      className="underline font-medium" onClick={(e) => e.stopPropagation()}>Prestashop Plugin
                      1.7</a>
                  </div>
                </div>
                <div className="mb-2">
                  <span className="font-semibold">Mobile SDK</span>
                  <div>
                    {t('mobileSdkParagraph')}
                  </div>
                </div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.pokpay.io/downloads/plugins/woocommerce/pokpaymentgateway-1.1.0.zip"
                  className="underline italic font-medium" onClick={(e) => e.stopPropagation()}>
                  Woocommerce Plugin 1.1.0
                </a>
              </div>
            }
            image={plugin}
          />
        </div>
      </div>
      <div className="footer-padding bg-brand-dark-blue"/>
    </Layout>
  );
};

export default OurServices;
